@import '../common/colors';

.login-page {
    background-color: $login-background-color;
    background-image: url('../../images/unaro-yellow-bg.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center top;
    height: 100vh;
    width: 100%;
    align-content: center;
    display: flex;
    align-items: center;
    justify-content: center;

    .login-form {
        width: 80%;
        max-width: 350px;
        z-index: 90;
        background-color: white;
        border-radius: 15px;
        padding: 20px;
        display: flex;
        flex-direction: column;

        form {
            display: flex;
            flex-direction: column;
        }

        .error-message-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            visibility: visible;
            top: -20px;
            background-color: red;
            padding: 15px;
            margin-left: -20px;
            margin-right: -20px;
            margin-bottom: 10px;

            label {
                color: white;
            }
        }

        .error-message-container-hidden {
            visibility: hidden;
        }

        .error {
            color: red;
            margin-top: 20px;
        }
    }

    .logo {
        width: 280px;
        align-self: center;
        margin-bottom: 10px;
    }

    input {
        border: 0;
        outline: 0;
        background: transparent;
        border-bottom: 1px solid #898989;
        background-color: rgba(196, 196, 196, 0.1);
        height: 10px;
        min-width: 200px;
        padding: 10px;
        font-size: 15px;
    }

    .password-input {
        margin-top: 20px;
    }

    input[type=submit] {
        margin-top: 30px;
        margin-bottom: 30px;
        min-width: 120px;
        height: 35px;
        border-radius: 25px;
        color: white;
        align-self: center;
        background-color: $accent-color;
        border: none;
        outline: 0;
        font-size: 12px;
    }
}


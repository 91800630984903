@import '../common/colors';

.register-page {
    background-color: $login-background-color;
    background-image: url('../../images/unaro-yellow-bg.svg');
    min-height: 100vh;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    user-select: none;

    .no-tt {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
        height: 80%;
    }
    
    .register-form {
        width: 70%;
        max-width: 400px;
        background-color: white;
        border-radius: 15px;
        padding: 40px;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .error-message-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            visibility: visible;
            background-color: red;
            padding: 15px;
            margin-bottom: 10px;

            label {
                color: white;
            }
        }

        .error-message-container-hidden {
            visibility: hidden;
        }

        .register-btn {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            background-color: $login-background-color;
            padding: 10px 20px;
            border-radius: 30px;
            width: fit-content;
            align-self: center;
            margin: 10px;
            width: 200px;

            label {
                font-family: Futura PT Book;
                font-style: normal;
                font-weight: normal;
                font-size: 30px;
                color: #FFFFFF;
                margin-right: 10px;
            }
        }

        .add-more {
            label {
                font-size: 20px;
            }
        }
    }

    .logo {
        height: 100px;
        max-width: 200px;
        align-self: center;
        margin: 20px;
    }

    .title {
        margin: 10px;
        font-weight: bold;
        font-size: 18px;
        line-height: 18px;
        color: $accent-color;
        font-family: Futura PT Bold;
        align-self: center;
    }

    input {
        border: 0;
        outline: 0;
        background: transparent;
        border-bottom: 0.6px solid #898989;
        height: 10px;
        padding: 10px;
        font-size: 15px;
        margin: 15px 0;
        font-family: Futura PT Book;
        border-radius: 0;
    }


    .birthday-input {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-bottom: 0.6px solid #898989;
        user-select: none;
        width: 97%;
        margin: 15px 0;
        padding: 5px;
        color: #666666;

        label {
            width: 120px;
        }

        input {
            height: 15px;
            width: fit-content;
            padding: 0px;
            margin: 0;
            border: none;
            color: #666666;
            font-family: Futura PT Book;
        }
    }
    .input-error {
        border-bottom: 0.6px solid #E85558;
    }

    .password-input {
        margin-top: 20px;
    }

    input[type=submit] {
        margin-top: 30px;
        margin-bottom: 30px;
        min-width: 120px;
        height: 35px;
        border-radius: 25px;
        color: white;
        align-self: center;
        background-color: $accent-color;
        border: none;
        outline: 0;
        font-size: 12px;
        font-family: Futura PT Book;
    }

    .privacy {
        font-family: Futura PT Book;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: #666666;
        margin-top: 5px;
        align-self: center;
    }

    .landing-page-nf {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
        height: 100%;
    }
}


@import '../common/colors';

.customers-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 100vh;
    background-image: url('../../images/background.svg');
    background-color: #eeeeee;
    user-select: none;

    .top {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .logo-menu {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            background-color: white;
            width: 100%;
            padding: 20px 0;

            .logo {
                min-width: 200px;
                justify-self: center;
            }

            .menu-btn {
                margin-right: auto;
                position: absolute;
                right: 20px;
                padding: 10px;
            }

            .menu-btn-selected {
                line {
                    stroke: #EA5454;
                }
            }
          
        }

        .menu {
            display: flex;
            flex-direction: column;
            background-color: white;
            align-items: center;
            width: 100%;
            position: absolute;
            top: 76px;

            label {
                text-align: center;
                padding: 15px;
                width: 100%;
                border-top: 0.5px solid #C4C4C4;
                font-family: Futura PT Book;
                font-weight: 300;
                font-size: 15px;
                color: #000000;
            }
        }

        .title {
            margin: 40px 0;
            font-weight: bold;
            font-size: 18px;
            line-height: 18px;
            color: #4D4D4D;
            font-family: Futura PT Bold;

            span:first-child, span:nth-child(3) {
                font-family: Futura PT Book;
            }

            span:nth-child(2) {
                font-family: Futura PT Book;
                color: #EA5454;
            }
        }

        .search {
            border: none;
            color: #4D4D4D;
            font-family: Futura PT Book;
            width: 75%;
            max-width: 500px;
            border-radius: 40px;
            padding: 10px 30px;
            margin-bottom: 30px;
            outline: none;
            font-size: 18px;
        }
    }
    
    *{
        -ms-overflow-style: none;
    }
    ::-webkit-scrollbar {
        display: none;
    }

    .customers-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        white-space: nowrap;
        overflow-y: scroll;     
        -webkit-overflow-scrolling: touch;
        height:55vh;
        max-width: 600px;
        width: 90%;
        overflow-x: hidden;
    }

    .bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;

        .add-btn {
            display: flex;
            flex-direction: row;
            align-items: center;
            background-color: $login-background-color;
            padding: 10px 40px;
            border-radius: 30px;
            margin-top: 10px;

            label {
                font-family: Futura PT Book;
                font-style: normal;
                font-weight: normal;
                font-size: 25px;
                color: #FFFFFF;
                margin-left: 10px;
            }
        }

        .privacy {
            font-family: Futura PT Book;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            color: #666666;
            margin-top: 5px;
        }
    }
}
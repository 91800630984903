.venues-popup {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    top: 0;
    left: 0;
    user-select: none;
    outline: 0;

    .venues-popup-content {
        display: flex;
        flex-direction: column;
        background-color: white;
        position: absolute;
        width: 90%;
        height: fit-content;
        margin: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        max-width: 500px;
        outline: 0;

        .venue-item {
            padding: 20px;
        }
        
        .venue-item::hover {
            background-color: #cccccc;
        }
    }
}
@import '../common/colors.scss';

.customer {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    padding: 10px 25px;
    border-radius: 30px;
    margin: 5px 20px;
    width: 85%;
    min-height: 80px;
    
    img {
        width: 75px;
        height: 75px;
        background-color: $accent-color;
        border-radius: 10px;
    }

    .names {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        flex: 1;

        hr {
            border: 0;
            clear:both;
            display:block;
            width: 98%;               
            background-color: #eeeeee;
            height: 1px;
            margin: 10px 0px;
        }

        .name {
            font-family: Futura PT Bold;
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            color: #666666;
            text-align: right;
        }

        .last-visit {
            font-family: Futura PT Cond Book;
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            text-align: right;
            color: #666666;
        }
    }
}
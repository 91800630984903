.registration-complete {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-image: url('../../images/background.svg');
    background-color: #eeeeee;
    flex: 1;

    .registration-complete-box {
        display: flex;
        flex-direction: column;
        margin: 20px;
        border-radius: 30px;
        background-color: white;    
        flex: 1;

        .registration-complete-top {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1;
            border-top-right-radius: 30px;
            border-top-left-radius: 30px;
            background-image: url('../../images/green-background.png');
        }

        .registration-complete-bottom {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex: 2;
            justify-content: space-around;

            .registration-complete-bottom-top {
                display: flex;
                flex-direction: column;
                align-items: center;

                .logo {
                    margin-top: 30px;
                    width: 25%;
                    min-width: 200px;
                    margin-bottom: 30px;
                }
    
                .title {
                    font-family: Futura PT Bold;
                    font-weight: bold;
                    font-size: 21px;
                    color: #4D4D4D;
                }
            }

           

            button {
                font-family: Futura PT Bold;
                font-size: 22px;
                background: #FF5558;
                border-radius: 29px;
                color: white;
                border: none;
                padding: 15px 40px;
                outline: 0;
            }

            .privacy {
                font-family: Futura PT Book;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                color: #666666;
                margin-top: 5px;
                align-self: center;
                
                a {
                    text-decoration: none;
                }
            }
        }

    }
}
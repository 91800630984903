.registration-complete-popup {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    top: 0;
    left: 0;
    user-select: none;
    outline: 0;

    .registration-complete-popup-content {
        display: flex;
        flex-direction: column;
        background-color: white;
        position: absolute;
        width: 90%;
        height: fit-content;
        margin: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        max-width: 500px;
        outline: 0;
        border-radius: 30px;

        .registration-complete-top {
            height: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-top-right-radius: 30px;
            border-top-left-radius: 30px;
            background-image: url('../../../images/green-background.png');
        }

        .registration-complete-bottom {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;

            .registration-complete-bottom-top {
                display: flex;
                flex-direction: column;
                align-items: center;

                .logo {
                    margin-top: 30px;
                    width: 25%;
                    min-width: 200px;
                    margin-bottom: 30px;
                }
    
                .title {
                    font-family: Futura PT Bold;
                    font-weight: bold;
                    font-size: 21px;
                    color: #4D4D4D;
                }
            }
        }
    }
}
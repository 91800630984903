.title-dropdown-container {
    display: flex;
    flex-direction: column;
    outline: 0;
    user-select: none;
    width: 100%;
    margin-right: 10px;

    .dropdown {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        outline: 0;
        font-size: 18px;
        line-height: 18px;
        border-bottom: 0.6px solid #898989;
        margin: 15px 0;
        background-color: inherit;
        padding: 5px;
        width: 100%;
    
        .down-icon {
            height: 10px;
            margin-left: 10px;
            transition-duration: 0.5s;
            transition-property: transform;
        }

        label {
            color: #666666;
            font-family: Futura PT Book;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 18px;
            padding-bottom: 2px;
        }

    }

    .dropdown-open {
        .down-icon {
            transform: rotate(180deg);
            margin-top: 5px;
        }
    }
    .options {
        position: absolute;
        display: flex;
        flex-direction: column;
        background-color: white;
        min-width: 290px;
        padding: 10px 0px 10px 0px;
        border: 1px solid grey;
        margin-top: 60px;
        z-index: 2;

        label {
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 20px;
            padding-right: 10px;
            font-size: 15px;
            color: #3C4858;
            font-family: Futura PT Book;
        }

        label:hover {
            background-color: grey;
            color: white;
        }
    }
}
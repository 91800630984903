.main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

@font-face {
    font-family: 'Futura PT Bold';
    src: local('Futura PT Bold'), url(../../fonts/FuturaPTBold.otf) format('opentype');
}

@font-face {
    font-family: 'Futura PT Book';
    src: local('Futura PT Book'), url(../../fonts/FuturaPTBook.otf) format('opentype');
}

@font-face {
    font-family: 'Futura PT Cond Medium';
    src: local('Futura PT Cond Medium'), url(../../fonts/FuturaPTCondMedium.otf) format('opentype');
}